exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-club-contact-js": () => import("./../../../src/pages/club-contact.js" /* webpackChunkName: "component---src-pages-club-contact-js" */),
  "component---src-pages-club-owners-js": () => import("./../../../src/pages/club-owners.js" /* webpackChunkName: "component---src-pages-club-owners-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-qr-redirect-js": () => import("./../../../src/pages/QRRedirect.js" /* webpackChunkName: "component---src-pages-qr-redirect-js" */),
  "component---src-pages-roadmap-js": () => import("./../../../src/pages/roadmap.js" /* webpackChunkName: "component---src-pages-roadmap-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-tournaments-js": () => import("./../../../src/pages/tournaments.js" /* webpackChunkName: "component---src-pages-tournaments-js" */),
  "component---src-templates-news-category-template-js": () => import("./../../../src/templates/NewsCategoryTemplate.js" /* webpackChunkName: "component---src-templates-news-category-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/NewsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */)
}

